import React, { useState } from "react"
import Layout from "../layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import { FiChevronsDown } from "react-icons/fi"

import JoinListForm from "../components/JoinList"

import logoImg from "../images/logo-full.png"

const IndexPage = ({ location }) => {
  const [showForm, setShowForm] = useState(false)

  return (
    <Layout pageId="home" location={location} logo={false}>
      <SEO title="Home" />
      <Section id="hero" customStyle={{ overflow: 'unset' }}>
        <div className="flex flex-col items-center text-center">
          <div className="home-logo">
            <img
              className="img logo--light"
              src={logoImg}
              alt="FanClub"
              style={{ maxWidth: 220 }}
            />
          </div>
          <h1 className="fs-24 fs-lg-32 fs-xl-42 font-medium pt-8 pb-4 ff-secondary">
            <span className="text-golden">A new social media experience</span>
          </h1>
          <p
            className="fs-16 fs-lg-20 fs-xl-24 text-center ff-secondary leading-normal"
            
          >
            FanClub is a community-based Web3 social media platform<br/>
            Discover and Engage with Content Creators, Brands, and Communities in a reimagined way
          </p>
          <h4 className="fs-16 fs-lg-18 fs-xl-20 text-center text-primary pt-12 ff-secondary">
            Sign up for an invite code below
          </h4>
          <span className="fs-20 fs-lg-24 fs-xl-30 text-center text-primary pt-4 pb-4">
            <FiChevronsDown />
          </span>
          <div className="text-center">
            <button className="fs-18 font-bold px-16 py-3 md:px-16 md:py-3 btn btn-white d-inline-block" onClick={() => setShowForm(true)}>
              <span className="text-gradient">Apply</span>
            </button>
          </div>
        </div>
        {showForm && <div className="flex flex-col items-center absolute w-full h-full left-0" style={{backgroundColor: "#121212"}}>
          <h1 className="fs-36 fs-lg-48 fs-xl-54 text-gradient font-bold ff-secondary">
            Sign Up
          </h1>
          <div className="flex flex-col justify-center items-center mt-24" style={{ width: "calc(100% - 4rem)", maxWidth: 420}}>
            <JoinListForm />
          </div>
        </div>}
      </Section>
    </Layout>
  )
}

export default IndexPage
